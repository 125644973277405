// extracted by mini-css-extract-plugin
export var blog_body = "blog-main-module--blog_body--b849a";
export var blog_main = "blog-main-module--blog_main--c8556";
export var blog_main_blog_date = "blog-main-module--blog_main_blog_date--0ac11";
export var blog_main_new_blog = "blog-main-module--blog_main_new_blog--f6cee";
export var blog_main_new_blog_container = "blog-main-module--blog_main_new_blog_container--be4c8";
export var blog_main_old_blog = "blog-main-module--blog_main_old_blog--fab21";
export var blog_main_old_blog_box = "blog-main-module--blog_main_old_blog_box--727fb";
export var blog_main_old_blog_flex = "blog-main-module--blog_main_old_blog_flex--6cb2f";
export var blog_main_old_blog_image = "blog-main-module--blog_main_old_blog_image--4c01b";
export var blog_main_old_blog_outside = "blog-main-module--blog_main_old_blog_outside--19fb9";
export var blog_main_topic = "blog-main-module--blog_main_topic--537c0";